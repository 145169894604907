<template>
  <div>
    <div v-if="loading" class="flex sm12 lg12">
      <div class="text--center pb-4">
        <div class="flex-center spinner-box">
          <spinner/>
        </div>
      </div>
    </div>
    <div v-if="!loading">
      <!-- Notifications -->
      <div>
        <div class="mb-3">
          <va-notification v-model="errorNotificationVisible" color="danger" input closeable>
            <va-badge color="danger">
              {{ $t('notificationsPage.alerts.error') }}
            </va-badge>
            {{ $t('growing.recipeEdition.saveErrorMessage') }}
          </va-notification>
        </div>
      </div>
      <div class="flex xs12">
        <at-recipe-timeline ref="recipeTimeline"
          :growing-recipe-encrypted="recipeEncrypted"
          :is-production-recipe="isProductionRecipe"
          :current-day="currentDay"
          :is-in-modal="false"
          :editable="true"
        />
      </div>
      <div class="flex xs12 text-right">
        <va-button @click="goBack">{{ $t('buttons.cancel') }}</va-button>
        <va-button @click="showSaveModal = true">{{ $t('buttons.save') }}</va-button>
      </div>
      <va-modal
        v-model="showSaveModal"
        :title=" $t('growing.recipeEdition.save') "
        :message=" isProductionRecipe ? $t('growing.recipeEdition.saveProductionMessage') : $t('growing.recipeEdition.saveMessage') "
        :noOutsideDismiss="true"
        :hideDefaultActions="true"
        ref="modal"
      >
        <div class="flex xs12">
          <div v-if="isProductionRecipe" class="flex xs12 ml-5">
            <div class="row">
              <div class="flex xs12 mb-3">
                <p>{{ $t('growing.recipeEdition.selectVariablesApplyChange') }}</p>
                <p>{{ $t('growing.recipeEdition.applyChangesAfectAll') }}</p>
              </div>
              <div class="flex xs4" style="font-size: 14px;" v-for="setting in settings" :key="setting.id">
                <va-tree-category isOpen :label="$t(`growing.recipeEdition.${setting.tag}`)" :icon="getGroupIcon(setting.tag)">
                  <va-tree-node v-for="variable in setting.variables" :key="variable.id">
                    <va-checkbox
                      slot="checkbox"
                      v-model="variable.selected"
                    />
                    {{ $t(`${setting.tag}Data.${variable.tag}`) }}
                  </va-tree-node>
                </va-tree-category>
              </div>
              <div class="save flex xs8 pl-5 mt-3" v-if="cycleChecked">
                <va-input
                  v-model="actionControlString"
                  type="string"
                  :placeholder="$t('growing.recipeEdition.confirmSaveSettingsPropagation')"
                />
                <p>{{ $t('growing.recipeEdition.applyChangesAfectAllAtencion') }}</p>
              </div>
            </div>
          </div>

          <div v-else class="flex xs12 ml-5">
            <div class="row">
              <div class="flex xs12">
                <va-checkbox
                  :label="propagateChangesLabel"
                  v-model="propagateChanges"
                />
              </div>
              <div v-if="propagateChanges" class="save flex xs6 pl-5">
                <va-input
                  v-model="actionControlString"
                  type="string"
                  :placeholder="$t('growing.recipeEdition.confirmSave')"
                />
              </div>
            </div>
          </div>
        </div>
        <template slot="actions">
          <div class="flex xs12 text-right">
            <va-button @click="cancelModal()" flat color="gray">
              {{ $t('modal.cancel') }}
            </va-button>
            <va-button
              :disabled="(isProductionRecipe && cycleChecked && actionControlString !== 'SAVE') || (!isProductionRecipe && propagateChanges && actionControlString !== 'SAVE')"
              @click="saveRecipe()" color="success">
              {{ $t('modal.confirm') }}
            </va-button>
          </div>
        </template>
      </va-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import * as socketio from '@/utilities/socketio'
import { structuredApiUrl } from '../../../utilities/socketio'
import { styleHelpers } from '@/services/atmosphere-ui'
import { dummyRecipeEmptyForPropagation } from '../../../data/dummy_recipe'

export default {
  name: 'recipe-growing-edit',
  data () {
    return {
      loading: true,
      recipeEncrypted: {},
      isProductionRecipe: false,
      valid: null,
      validProductionRecipeNumber: null,
      showSaveModal: false,
      errorNotificationVisible: false,
      errorValidRecipeNotificationVisible: false,
      propagateChanges: false,
      settings: [],
      actionControlString: '',
      currentDay: -1,
    }
  },
  mounted () {
    this.$route.meta.parentName === 'farm-overview' ? this.$level.value = 'ground' : this.$level.value = 'bird'
    this.validateRoute()
  },
  methods: {
    getGrowingRecipeId () {
      return parseInt(this.$route.params.recipeId)
    },
    validateRoute () {
      if (this.getGrowingRecipeId() === 0) {
        this.$router.push({ path: '/404/page-not-found' })
      } else {
        this.getEncryptedRecipe()
      }
    },
    validateProductionRecipeNumber () {
      axios.get(`${structuredApiUrl}/farms/${this.activeFarm}/control-groups/${this.$route.params.roomId}/control-areas/${this.$route.params.areaId}`)
        .then(response => {
          const recipeNumber = response.data.data.production_growing_recipe_id
          this.validProductionRecipeNumber = parseInt(recipeNumber) === parseInt(this.$route.params.recipeId)

          this.currentDay = this.getCurrentDay(response.data.data.work_order_start_date, response.data.data.work_order_end_date)
          if (this.validProductionRecipeNumber) {
            this.getRecipeSettings()
            this.loading = false
          } else {
            this.loading = false
            this.$router.push({ path: '/404/page-not-found' })
          }
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
          this.$router.push({ path: '/404/page-not-found' })
        })
    },
    getCurrentDay (startDate, endDate) {
      if (startDate === null || endDate === null) {
        return -1
      }

      const start = new Date(startDate + 'Z')
      start.setUTCHours(0, 0, 0)
      const end = new Date(endDate + 'Z')
      end.setUTCHours(23, 59, 59)

      const secTotal = (end - start) / 1000 // s
      const secPassed = (new Date() - start) / 1000

      if (secTotal < 0) {
        return Math.floor(secTotal / (24 * 60 * 60))
      } else if (secPassed > secTotal) {
        return -1
      } else {
        return Math.floor(secPassed / (24 * 60 * 60))
      }
    },
    getRecipeSettings () {
      this.settings = dummyRecipeEmptyForPropagation.content
      this.settings.forEach(group => {
        group.variables.forEach(variable => {
          this.$set(variable, 'selected', false)
        })
      })
    },
    getGroupIcon (group) {
      let icon = 'at-icon l_'

      switch (group) {
        case 'water':
          icon += 'water1drop'
          break

        case 'air':
          icon += 'airflow'
          break

        case 'light':
          icon += 'weather_sunny'
          break
      }

      return icon
    },
    getEncryptedRecipe () {
      axios.get(`${structuredApiUrl}/growing-recipes/${this.getGrowingRecipeId()}`)
        .then(response => {
          this.recipeEncrypted = response.data.data
          this.isProductionRecipe = this.recipeEncrypted.tags.some(tag => {
            return tag === 'production-growing-recipe'
          })

          this.validRoute = (!this.isProductionRecipe && !this.$route.meta.productionRecipe) || (this.isProductionRecipe && this.$route.meta.productionRecipe)
          if (!this.validRoute) {
            this.loading = false
            this.$router.push({ path: '/404/page-not-found' })
          }

          if (this.isProductionRecipe) {
            this.validateProductionRecipeNumber()
          } else {
            this.loading = false
          }
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
          this.$router.push({ path: '/404/page-not-found' })
        })
    },
    getPropagationSettings () {
      const propagateStrings = []
      for (const group in this.settings) {
        for (const variable in this.settings[group].variables) {
          if (this.settings[group].variables[variable].selected) {
            propagateStrings.push(this.settings[group].tag + '_' + this.settings[group].variables[variable].tag)
          }
        }
      }
      return propagateStrings
    },
    saveRecipe () {
      if (this.$refs.recipeTimeline.validateRecipe()) {
        this.$refs.recipeTimeline.addLastUpdateTimeStamp()
        if (this.isProductionRecipe) {
          this.$refs.recipeTimeline.checkManualChanges()
        }
        this.recipeEncrypted.recipe = this.$refs.recipeTimeline.encryptRecipe()

        const saveRecipe = {
          name: this.$refs.recipeTimeline.getRecipeName(),
          recipe: this.recipeEncrypted.recipe,
          description: this.$refs.recipeTimeline.getRecipeDescription(),
        }

        if (this.isProductionRecipe) {
          saveRecipe.duration_days = this.$refs.recipeTimeline.getRecipeDurationDays()
        }

        axios.put(`${structuredApiUrl}/growing-recipes/${this.recipeEncrypted.id}`, saveRecipe)
          .then(response => {
            if (response.status >= 200 && response.status < 300) {
              this.$store.commit('setSaveRecipe', { showState: true, success: true, recipeNumber: this.recipeEncrypted.id, message: 'saveSuccessfullyMessage' })
              if (this.isProductionRecipe) {
                const layersToPropagate = this.getPropagationSettings()
                if (layersToPropagate.length !== 0) {
                  socketio.initialiseSocket()
                  socketio.socket.emit('propagate_production_growing_recipe', 1, this.$route.params.roomId, this.$route.params.areaId, layersToPropagate)
                }
              } else if (!this.isProductionRecipe && this.propagateChanges) {
                socketio.initialiseSocket()
                socketio.socket.emit('propagate_growing_recipe', 1, this.recipeEncrypted.id)
              }
              this.goBack()
            } else {
              this.errorNotificationVisible = true
            }
          })
          .catch(function (error) {
            console.log(error)
          })
      } else {
        console.log('The recipe is not correct')
      }
      this.$refs.modal.close()
    },
    goBack () {
      this.$router.back()
    },
    cancelModal () {
      this.getRecipeSettings()
      this.propagateChanges = false
      this.actionControlString = ''
      this.$refs.modal.cancel()
    },
  },
  computed: {
    activeFarm () {
      return this.$farmId ? this.$farmId : parseInt(process.env.VUE_APP_FARM_ID)
    },
    computedStyle () {
      const style = {
        borderRadius: styleHelpers.genRadius({ topLeft: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 }),
      }
      return style
    },
    propagateChangesLabel () {
      return this.isProductionRecipe
        ? `${this.$t('growing.recipeEdition.propagate')} ${this.$t('growing.recipeEdition.inRoom')}`
        : `${this.$t('growing.recipeEdition.propagate')} "${this.recipeEncrypted.name}" ${this.$t('growing.recipeEdition.inProduction')}`
    },
    cycleChecked () {
      return this.settings.some(setting => {
        return setting.variables.some(variable => {
          return variable.selected
        })
      })
    },
  },
}
</script>

<style lang="scss">
.va-card-table.recipes-card .va-card__body {
  padding: 0;
  overflow-y: hidden;
}

.save {
  .va-input__container__input {
    padding-bottom: 0.1em !important;

    &::placeholder {
      text-transform: none;
    }
  }
}

.va-modal__overlay.recipes-modal {
  .va-modal.va-modal--size-large {
    width: 90vw;
    max-width: 90vw;

    .va-modal__inner {
      max-width: 100%;
      padding: 0;

      .title {
        margin-left: 1.25rem;
        font-size: 1rem;
      }
    }

    .va-modal__message {
      width: 100%;
    }
  }
}

</style>
